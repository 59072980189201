import './css/app.css';
import logo from './images/logo.png';
import LogRocket from 'logrocket';
import '@fontsource/inter';
import Button from '@mui/joy/Button';
LogRocket.init('comerso/patriot-moving-systems-vwnc6');

function App() {
  return (
    <div className="app">
      <div className="container">
        <div className="fade-in-message">
        <p>A Local Trusted Michigan Moving Company for 30 Years!</p>
        </div>
        <div className="fade-in-logo-icon">
          <img src={logo} alt="Patriot Moving Systems" />
        </div>
        <div className="fade-in-logo-text">
          <p></p>
          <p>CALL FOR A FREE MOVING QUOTE TODAY!</p>
        </div>
        <div className="fade-in-cta"> 
          <Button variant="solid">Contact Us</Button>      
          <p><a href="tel:+1-586-755-5600">586-755-5600</a></p>
        </div>



{
/* Let us help you move today!
TOLL FREE 1-888-755-0091
If you have a question regarding your move, please refer to the frequently asked questions listed to the left. If your question is not answered, please contact us for more information.

We are a moving company headquartered in Michigan serving Macomb, Oakland, and Wayne counties.

Here are the reasons why you should let us help you move:

We have been in business for 25 Years!
Friendly and trustworthy movers
Licensed and Insured Michigan Moving Company
Family Owned and Operated
Experienced, Careful, AND Efficient
Full Service Packing at Affordable Rates
Corporate Relocation Packages
Local AND Long Distance Moving Capabilities
Home Moving
Office Moving Services
Local Michigan Moving Company
We’ll Move Pianos and Pool Tables!



We Sell Boxes and Packaging Supplies


Standard moving boxes
Record or Book Boxes
Lamp Box
Large Boxes
Extra Large boxes
Picture moving boxes
Wardrobe moving boxes
Dish moving boxes
Dish pack inserts


=============
Moving Services
Household Moving

We offer you a full service home moving service. We always strive to exceed your expectations in quality of service with a friendly and courteous staff. If you are moving just down the road or across town our local moving services are second to none. We are fully licensed, insured and bonded with absolutely no hidden fee or changes. We sell boxes and also offer full packaging services for your moving needs.

Corporate Relocating

Corporate relocation across town or across state requires dedicated and strategic planning by our moving team. We dedicate a team to stay with your company the duration of the move. We have experience in many industries including professional offices, retail stores, educational institutions, manufacturing and much more.

Local Moving

We locally move homes, businesses, apartments, condominiums and everything else. If you need help moving a room or an entire estate our fully licensed and insured company and professionally trained staff can package and deliver your items. Our fleet of Patriot Moving trucks are always maintained and kept super clean to ensure the integrity of the delivery.

Long Distance Moving

Patriot Moving offers a great long distance moving service. We understand that a long move can be daunting and we are here to provide you with a full service move that exceeds your expectations in experience. We want your long distance move to be smooth as possible. Our professionally trained, friendly and courteous staff will be with your treasured items during the entire move even if its a cross country move.

Assembling Services
Commercial Moving Services
Corporate Moving
Furniture Moving
Large and Heavy Item Moving
Local Moving
Long Distance Moving
Packing, Unpacking, & Crating
Piano Moving
Senior Moving
Storage Services
Specialties
Patriot Moving Systems is a full service moving company and a moving company you can trust. We offer a wide variety of services including, residential & commercial moving, full service packing , local & long distance, pianos, pool tables, Gun Safes & more. We also load and unload Pods and ABF containers, load and unload rented trucks. Junk removal and cleanouts. We specialize in professionalism from start to finish. Your furniture will be blanketed and shrink-wrapped or banded. We will show up on time. I guarantee that my crew will work fast and hard all day long. They will respect you and your furniture and it is their job to provide you with the highest quality of service possible. Call now for your free estimate. Let our family move yours

Established in 1997.

Patriot Moving Systems is a family-owned and operated company.

I began Patriot Moving Systems in the summer of 1997 we are a family-owned and operated company with the goal of providing high-quality hassle-free moving to customers.

Now, over 20 years later, we are much bigger, stronger and more experienced. The one thing that has not changed in our number one goal is providing that high-quality hassle-free moving. Please don’t hesitate to call, let our family move yours.

Business Owner
Dennis M.

============


Customer Reviews
Our original moving company cancelled on day of move. Your company came through for us! Movers were very professional and helpful. Very smooth move considering circumstances thanks to Patriot!! I would not recommend anyone else except for Patriot for friends and family moving needs.
Steve Taravella - Chesterfield Twp., Michigan
Very efficient, mannerly, hard working crew! I will recommend to family and friends.
Julie Doss - Washington Twp., Michigan
The three guys that helped us move were fantastic. Careful, quick, courteous, professional, fun, funny, respectful, nice and efficient. I could not have been happier. I was pleasantly surprised at what a tremendous job they did. Thank you for fabulous customer service.
Elaine Dunlap - Sterling Heights, Michigan
These two gentlemen really busted their backs and worked non-stop both at loading & unloading. Very cooperative in putting everything just where we wanted it. Thanks for helping to make my first move in 26 years go smooth!
Jan Hansen - Clarkston, Michigan
We will call you again when our house is ready to move us again. The moving guys were great. We will request them next time.
Ermi Che - Novi, Michigan
After such a long day(we were the 2nd job scheduled). Your guys still maintained a great attitude and work ethic. I very much appreciated their professionalism.
Linda Witt - Gross Point Shores, Michigan
Very good service and were very nice to work with. I would recommend your services to anyone.
FEC, Inc. - Macomb Twp., Michigan

======================

Customer Service
Frequently Asked Questions
How long does it take to move?
This depends on many factors, such as weather conditions, size of your shipment, and distance your shipment is moving.
When is the best time to move?
ANYTIME that is most convenient for you! If you are planning a move we suggest avoiding end of the month, holidays, and summer time with extremely hot temperatures.
Is your moving company licensed?
YES. It would be more accurate to say that we are registered as a motor carrier by the U.S. Department of Transportation and currently hold a certificate of authority since 1997.
Is your moving company insured?
YES.
Do I need an estimate?
YES. We will take a survey of your household goods to be transported. A survey prior to your move is needed to determine the estimated cost. There is absolutely no charge for the estimate.
Am I protected against loss or damage while my goods are in transit?
YES.
Can I move my pet(s)?
NO. We cannot be liable for your pet’s safety during transportation.
Do my appliances need special attention?
YES. You should empty any refrigerated appliance and keep the doors open for at least 24 hours in advance of moving. This will allow appliances to completely dry out and prevent the growth of mold.
Can I pack my china, glass and crystal?
YES. Most people prefer to have their household possessions, especially fragile items, professionally packed by a moving company. However, if you decide to pack these items yourself, remember that the basic principles of good packing include wrapping the items individually, providing plenty of cushioning and making sure of a firm pack.
Can I move my frozen foods?
YES. Frozen foods can be moved, but only under limited conditions. This is solely up to the discretion of the Patriot Moving Systems representative estimating your move.
Can I move jewelry and other valuables?
YES. In the moving industry, items worth more than $100 per pound are considered to be articles of “extraordinary” value. Be sure to let the Patriot Moving Systems representative know ahead of time that you will be shipping valuable items.
Can I move my house plants?
NO. We cannot be liable for your plants safety during transport.

=======

Copyright © 2019 Patriot Moving Systems, LLC. | All Rights Reserved.

Michigan Moving Company Family Owned and Operated for 25 Years */}





      </div>
    </div>
  );
}

export default App;